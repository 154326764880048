@import "./mixins.scss";

.pointer{
    &:hover{
        cursor: pointer;
    }
}

.sticky-top-offset{
    @media (min-height: 900px) and (min-width: 767.98px) {
        position: -webkit-sticky;
        position: sticky;
        top: 80px;
        background: white;
        z-index: 9;
    }  

}

.noPaddingOnDesktop{
    @media (min-width: 767.98px) {
        padding: 0!important;
    }
    
}

.marginRightMobile{
    @media (min-width: 767.98px) {
        padding-right: 0 !important
    }
    
}
.noMarginRightMobile{
    @media (max-width: 767.98px) {
        padding-right: 0 !important
    }
    
}

.left-padding{
    @media (min-width: 767.98px) {
        padding-left:50px
    }  
}

.form-label-group label{
    height: 0
}

button, a, .btn{
    @include buttonActionStyle();
}
.dropdown-item.active, .dropdown-item:active{
    background-color: $primary-color;
}
.dropdown-menu{
    padding-bottom: 0  !important;
}

.red-text{
    color:#C62727
}

.invalid-highlight {
    box-shadow: 0px 0px 10px 2px rgba(255, 0, 0, 0.5);
}

/* App Banner*/
.app-banner-container-parent{
    background-color: white;
}
.app-banner-container{
    background-color: white;
    p{
        color: black;
        line-height: normal;
        margin: 0px;

        &.banner-title{
            @include categoryHeadline();
        }
        &.banner-title-app{
            font-size: 40px;
            font-weight: normal;
            margin-bottom: 30px;
        }
        &.banner-text{
            font-size: 14px;
            margin-bottom: 30px;
        }
    }
    .banner-text-part{
        padding: 50px 0px 50px 0px;
        @media (max-width: 1199.98px) {
            padding-left: 30px;
        }
        @media (max-width: 767.98px) {
            padding: 50px 30px 50px 30px;
        }
    }
    .banner-image{
        height: 50px;
        margin-right: 10px;
        @include buttonActionStyle();
        @media (max-width: 397.98px) {
            height: 40px;
        }
    }
    .banner-app-image{
        padding: 20px 0px 20px 100px;
        background-image: url("../resources/app-on-mobile.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-origin: content-box;
        @media (max-width: 767.98px) {
            height: 40vh;
            background-position: center;
        }
    }

    
}

/* Where are we section*/
.where-are-we-parent{
    padding: 50px 0px 50px 0px;
    background: linear-gradient(rgba(220,220,220,0.70), rgba(220,220,220,0.70)),
    url('../resources/mapBackground.png');
    background-repeat: no-repeat;
    background-size: cover;
    
    
    @media (max-width: 767.98px) {
        padding: 50px 20px 50px 20px;
    }
    .where-are-we-title{
        color: #78959a;
        font-size: 40px;
        font-weight: 250;
        
    }
    .where-are-we-title-icon{
        color: #78959a;
        font-size: 40px;
        font-weight: 250;
    }
}
.city-link{
    font-size: 30px  !important;
    font-weight: 200;
    vertical-align: middle;
    color: grey;
    background-color: white;
    display: inline-block;
    border: 1px solid $darker-grey-border;
    margin-right: 30px;
    margin-bottom: 20px;
    
    @include buttonActionStyle();
}

.city-link-delivery{
    font-size: 30px  !important;
    line-height: 40px;
    font-weight: 200;
    vertical-align: middle;
    color: black;
    background-color: white;
    display: inline-block;
    border: 2px solid black;
    padding: 5px 15px 5px 15px;
    margin-right: 30px;
    margin-bottom: 20px;
    
    @include buttonActionStyle();
}

.city-link-delivery:hover{
    border: 2px solid #C62727;
    color: #C62727;
}

/*Become Member*/
.become-member-parent{
    padding: 50px 0px 50px 0px;
    border-bottom: 2px solid #eef0ef;
}

/* Advertising Banners */
.banner-advertising-parent{
    padding-top: 20px;
    padding-bottom: 10px;
    h3{
        @include categoryHeadline();
        margin-bottom: 25px;
    }

    .banner-advertising{
        @include buttonActionStyle();

        margin-bottom: 10px;
        margin-top: 10px;
        
        img{
            width: 100%;
            object-fit: contain;
            max-height: 174px;
            @media (max-width: 767.98px) {
                margin-top: 20px;
            }
        }
        
    }
    

}

/*Restaurant Offers*/
.restaurant-offers{
    
    padding: 25px 0px 50px 0px;
    background-color: #eef0ef;
    .columns{
        margin-top: 20px
    }
    .newest-offers{

        h3{
            @include categoryHeadline();
            margin-bottom: 20px;
        }
    }
    .all-restaurants{
        h3{
            @include categoryHeadline();
            margin-bottom: 25px;
        }
    }
}

@mixin cart-button {
    font-size: 15px;
    width: 24px;
    height: 24px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    border: 1px solid #ced4da;
    background-color: white !important;
    line-height: 20px;
    vertical-align: middle;
    float: right;
    &:hover{
        cursor: pointer;
    }
    
}
/*Cart*/
.cart-minus-button{
    @include cart-button();
}
.cart-plus-button{
    @include cart-button();
}
.order-cart{
    background-color: white;
    &.order-cart-border{
        border: 1px solid #ced4da;
    }
    .order-cart-title{
        color: $primary-color;
    }
    .order-cart-price{
        color: $primary-color;
    }
    .order-cart-item{
        padding-bottom: 5px;
        &.addPadding{
            padding-bottom: 40px;
        }
        border-bottom: 1px solid #ced4da;
    }
    .order-cart-item-text{
        color: black;
    }
    .order-cart-transport{
        padding: 20px 0px 20px 0px;
        background-color: #eef0ef;
        .order-cart-transport-checkbox{
            @include radioButtonStyle();
        }
    }
    

    .order-cart-added-item{
        padding: 12px 0px 12px 0px;
        border-bottom: 1px solid #ced4da;
        .order-cart-added-item-text{
            color: black;
            &.green-text{
                color: #41a565;
            }
        }
        .order-cart-added-item-price{
            color: $primary-color;
            float:right;
        }
    }
    .order-cart-price-total{
        padding: 8px;
        background-color: #eef0ef;
        
        .order-cart-price-total-text{
            color: $primary-color;
            font-size: 16px;
        }
        .order-cart-price-total-price{
            color: $primary-color;
            float:right;
            font-size: 16px;
        }
    }
    .order-cart-submit{
        background-color: $primary-color;
        color: white;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 20px;
        padding: 10px;
        line-height: 100%;
    }
    
    
}
.cart-dropdown-title{
    color: $primary-color;
    font-size: 20px !important;
}
.cart-dropdown-subtitle{
    color: black;
    font-weight: 300;
}

.quantButton{
    width: 30px !important;
    height: 30px !important;
    line-height: 26px !important;
}
.quantSpan{
    float: left;
    margin-top: 4px;
    line-height: 26px;
    padding: 0 5px;
    font-size: 16px;
}

/* Orders - big card*/
.add-to-cart-parent{
    text-align: right;
}
.add-to-cart-button{
    padding: 7px 5px 7px 5px  !important;
    border: 0px !important;
    background-color: $primary-color !important;

    @include buttonActionStyle();
    @media (max-width: 767.98px) {
        margin-top: 10px;
        width: 100%;
    }
}
.order-big-card-item-price{
    margin-top: 5px;
    display: block;
    color: $primary-color;
    font-size: 1.2em;
}

.order-big-card-title{
    font-size: 40px;
    font-weight: lighter;
}
.order-big-card-category-parent{
    border: 1px solid $darker-grey-border;
    width: 100%;
    background-color: white;
}
.order-big-card-category-title{
    color: $primary-color;
}
.order-big-card-categories{
    display: inline-block;
}
.order-big-card-single-category{
    color: grey;
    background-color: white;
    display: inline-block;
    border: 1px solid $darker-grey-border;
    padding: 5px 10px 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    @include buttonActionStyle();
   
}
.order-big-card-item-description{
    font-weight: 200;
    color: black;
    font-size: 13px;
    line-height: 1rem;
    margin: 0px;
    /* padding-right: 25px; */
    text-align: justify;
}
.order-big-card-item-alergens{
    font-weight: 300;
    color: grey;
    font-size: 14px;
    margin: 0px;
}

/*Extra Items*/
.extra-items{
    .extra-items-close-button{
        float: right;
        font-size: 20px;
        line-height: 5px;
        padding: 5px;
        text-align: center;
        background-color: $primary-color  !important;
        border-radius: 30px;
        @include buttonActionStyle();
    }
    .extra-items-title{
        color: $primary-color;
        font-size: 25px;
        font-weight: 200;
        margin: 0px;
    }
    .extra-items-food-title{
        color: black;
        margin-top:15px !important;
        font-size: 14px  !important;
        font-weight: bold;
        margin: 0px;
        
        .extra-items-food-title-alergens{
            color: $primary-color;
            font-weight: normal;
            font-size: 10px;
            margin-left: 5px;  
        }
    }
    .extra-items-food-description{
        font-size: 12px  !important;
        font-weight: 220;
        padding-bottom: 10px;
        margin-top: 0 !important;
        border-bottom: 1px solid $darker-grey-border;
    }
    .extra-items-category-title{
        font-size: 20px;
        color: $primary-color;
        
    }
    .extra-items-form-group{
        margin: 0px;
        padding: 0px;
        font-size: 14px;
        line-height: 14px;
        .form-label{
            color: grey;
        }
    }
    .extra-items-line{
        border-bottom: 1px solid $darker-grey-border;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .extra-items-add-to-cart{
        margin-top: 20px;
        padding: 10px 5px 10px 5px  !important;
        border: 0px !important;
        background-color: $primary-color !important;
        width: 100%;
        font-size: 20px;
        @include buttonActionStyle();
    }
    .extra-items-price{
        float: right;
    }
    
}
//Výběr rest
.chooseRest{
    width:100%;
}

.chooseRestAlt{
    background-color: white !important;
    color: black;
    border-width: 2px;
    margin-bottom: 20px;
    &:hover{
        color: black;
    }
}

/*Dropdown login & register*/
.dropdown-login{
    border-top: 2px solid $primary-color;
    min-width: 340px;
    padding: 20px;
}
.dropdown-login button{
    background-color:white !important
}
/*dropdown account*/
.dropdown-account{
    border-top: 2px solid $primary-color;
}


/*Checkout*/
.offer-dedicated-body{
    background-color: white;
}
.checkout-parent{
    margin-top: 20px;
    .order-more{
        display: inline-block;
        span{
            color: $primary-color;
            font-size: 20px;
            margin-right: 10px;
            font-weight: 250;
            vertical-align: middle;
        }
        button{
            border-radius: 0px;
            vertical-align: middle;
        }
    }
    .checkout-extra-drink{
        margin-top: 15px;
        
        .checkout-extra-drink-title{
            color: black;
            font-size: 20px;
            font-weight: 250;
            padding-bottom: 20px;
        }
    }
    .checkout-cook-notes{
        @include inputIcon();
        .input_img{
            font-size: 50px;
            height: 50px;
            top: 5px;
        }
    }
    .checkout-courier-notes{
        @include inputIcon();
    }
    .checkout-section-header{
        color: $primary-color;
        
        .checkout-section-number{
            background-color: $primary-color;
            color: white;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            font-size: 20px;
            vertical-align: middle;
            display: inline-block;
            float: left;
        }
        .checkout-section-title{
            display: inline-block;
            margin-top: 20px;
            margin-left: 10px;
            color: $primary-color;
            font-size: 25px;
            font-weight: 250;
            
        }
    }
    .checkout-section-chosen{
        margin-top: 15px;
        margin-left: 40px;
        max-width: 80%;
        span{
            font-size: 16px;
            font-weight: 300;
            &.checkout-section-chosen-title{
                color: $primary-color;
            }
        }
    }
   
    .checkout-delivery-time{
        background-color: white;
        border: 1px solid $darker-grey-border;
        padding: 20px;
        padding-bottom: 40px;
       
        .checkout-delivery-time-body{
            margin-top: 50px;
            text-align: center;
            
            .checkout-delivery-time-button{
                color: black;
                background-color: white  !important;
                border: 1px solid $darker-grey-border !important;
                width: 100%;
                font-weight: 600;
                @include checkoutHover();
                @include buttonActionStyle();
            }
        }
        
    }
    .collapse-button{
        float: right;
    }
    .checkout-delivery-address{
        background-color: white;
        border: 1px solid $darker-grey-border;
        padding: 20px;
        padding-bottom: 40px;
        .checkout-delivery-address-body{
            
            margin-top: 50px;
        }
    }
    .checkout-payment{
        background-color: white;
        border: 1px solid $darker-grey-border;
        padding: 20px;
        padding-bottom: 40px;
        .checkout-payment-body{
            
            margin-top: 50px;
        }
    }
}

.AddresspickText{
    border-bottom: 1px dashed grey;
    &:hover{
        cursor: pointer;
    }
}
/* Payment method */
.payment-method{
    text-align: center;
    color: grey;
    background-color: $lighter-grey-background;
    border: 1px solid $darker-grey-border;
    padding: 20px;
    margin-bottom: 20px;
    height: 90%;
    .payment-method-icon{
        display: block;
        font-size: 40px;
        margin-bottom: 10px;
    }
    .payment-method-image{
        margin-left: auto;
        margin-right:auto ;
        height: 35px;
        display: block;
        margin-bottom: 10px;
    }
    span{
        color: black;
        font-size: 15px;
        font-weight: 300;
    }
    @include checkoutHover();
    @include buttonActionStyle();
}
.courier-tip{
    background-color: $lighter-grey-background;
    border: 1px solid $darker-grey-border;
    padding: 20px;
    margin-bottom: 20px;
    height: 90%;
    .courier-tip-icon{
        display: block;
        font-size: 60px;
        text-align: center;
    }
    .courier-tip-text{
        text-align: center;
    }
    .courier-tip-buttons{
        width: 100%;
        
    }
    .courier-tip-button{
        color: black;
        font-weight: bold;
        background-color: white  !important;
        border: 1px solid $darker-grey-border !important;
        &:nth-child(2) {
            margin-left: 7px;
            margin-right: 7px;
        }
    }
    .courier-tip-input{
        margin-top: 10px;
        
    }
    .courier-tip-ok{
        font-weight: bold;
        color: black;
        background-color: white  !important;
        border: 1px solid $darker-grey-border !important;
    }
}

/*track-order*/
.track-order-circle{
    background-color: grey;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    font-size: 35px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    &.filled{
        background-color: $primary-color;
    }
}
.track-order-circle-time{
    height: 50px;
    margin-bottom: 50px;
    line-height: 50px;
    vertical-align: middle;
    color: $primary-color;
}
.track-order-circle-description{
    h6{
        margin: 0px;
        color: grey;
    }
    span{
        color: grey;
        font-weight: 300;
    }
    &.filled{
        h6{
            color: $primary-color;
        }
        span{
            color: black;
        }
    }
}
.track-order-circle-line{
    stroke: grey;
    &.filled{
        stroke: $primary-color;
    }
}
.track-order-header{
    border-bottom: 1px solid $darker-grey-border;
}
.track-order-detail{
    float:right;
    color: $primary-color;
    text-decoration: underline;
    &:hover{
        cursor: pointer;
    }
}



.add-address-nick-button{
    background-color: #10ac84  !important;
    &.active{
        background-color: #3ecf8e  !important;
    }
    
}

.detail-gallery-image{
    padding-top: 100%;
    background-size: cover;
    background-repeat: no-repeat ;
    @include buttonActionStyle();
}




/* Card added styles*/
.card-delivery-price{
    float: right;
    vertical-align: middle;
    .card-delivery-price-icon{
        font-size: 20px;
        vertical-align: middle;
    }
    .card-delivery-price-text{
        color: black;
    }
}
.card-information-icons{
    font-size:25px;
    color: grey;
    text-align: center;
    .card-information-icon{
        padding: 5px;
    }
}

/* My account Credits*/

.my-account-credits{
    text-align: center;
    .my-account-credits-input{
        text-align: center;
        padding: 5px 12px;
        font-size: 16px;
        vertical-align: middle;
        @media (max-width: 464px) {
            margin-bottom: 10px;
        }
    }
    .my-account-credits-button{
        background-color: $primary-color;
        vertical-align: middle;
    }
    .my-account-credits-links{
        color: $primary-color;
        cursor: pointer;
        text-decoration: underline;
        
    }
    .my-account-credits-stats{
        display: grid;
        grid-template-columns: 3fr 2fr;
        column-gap: 10px;
        grid-auto-rows: 1fr;

        @media (max-width: 575.98px) {
            grid-template-columns: 4fr 1fr;
        }
        .my-account-credits-stats-left{
            text-align: right;
            color: black;
            vertical-align: middle;
            
        }
        .my-account-credits-stats-right{
            text-align: left;
            font-weight: bold;
            color: $primary-color;
            vertical-align: middle;
            
        }
        
    }
}

/* My account statistics */
.my-account-statistics{
    .my-account-statistics-titles{
        color: $primary-color;
        font-size: 20px;
        font-weight: 350;
    }

    .my-account-statistics-favorites-parent{
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 10px;
    }
    .my-account-credits-right{
        color: $primary-color;
    }
}


/* Restaurant info*/
.restaurant-info-parent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media(max-width: 1029.8px){
        grid-template-columns: 1fr;
    }
    .restaurant-info-parent-left{

    }
    .restaurant-info-parent-right{
        @media(max-width: 1029.8px){
            text-align: center;
            
            iframe{
                width: 100%;
                height: 50vh;
            }
        }
    }
}
.restaurant-info-title{
    font-size: 27px;
    font-weight: 200;
}
.restaurant-info-name{
    font-size: 20px;
    color: $primary-color;
}
.restaurant-info-table{

    tr{
        border: none;

        td{
            border: none;
        }
    }
    .restaurant-info-table-icon{
        font-size: 25px;
        width: 40px;
        padding: 0;
        text-align: center;
        vertical-align: middle;
        color: grey;
    }
    .restaurant-info-table-text{
        padding: 0;
        vertical-align: middle;
        color: grey;
        strong{
            color: black;
        }
    }
}

.offer-dedicated-body-left .row{
    @media (max-width: 767.98px) {
        margin-right: 0!important;
    }
}


.offer-dedicated-body-absolute-image-parent{
    top:0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    background-color: rgba(178, 190, 195, 0.8);
}
.offer-dedicated-body-absolute-image{
    margin-top: 10vh;
    width: 80vw;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    position: relative;
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background: url("https://www.jidlopodnos.cz/data/restaurace/logo/131.jpg");
}
.menu-logo{
    @media (max-width: 991.98px) {
        //display: none;
    }
}

.offer-dedicated-body-container{
    width: 95vw;
}
.offer-dedicated-nav{
    border-bottom: 1px solid $darker-grey-border;
}

#navbarNavDropdown{
    @media (max-width: 767.98px) {
        position: absolute;
        top: 80px;
        left:0;
        width: 100%;
        max-height: calc(100vh - 80px);
        overflow-y: scroll;
        z-index: 9999;
    }
}

.order-display-cart-mobile-button{
    position: fixed;
    z-index: 999;
    bottom: 25px;
    width: 95vw;
    text-align: center;
    margin-left: 2.5vw;
    padding: 10px 0;
    font-weight: bold;
    font-size: 20px;
}

.order-absolute-cart-mobile{
    width: 100vw;
    height: 100vh;
    background-color: red;
    position: fixed;
    z-index: 999999;
}

//Topsearch autocomplete
.search-results{
    //display: none;
    //height: 0%;
    border: 1px solid rgb(206, 212, 218);
    padding: 0; 
    background-color: #fff;
    margin: 0;
    /* position: absolute; */
    z-index: 2;
    max-height: 216px;
    overflow: scroll;
    li {
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0 24px;
        position: relative;
        cursor: pointer;
        background-color: #fff;
        transition: 0.2s background-color;
      }
      li:hover{
          background-color: rgb(218, 218, 218);
      }
      i{
          padding-top: 5px;
          padding-right: 5px;
      }
}

/* All rest  */

.list-card{
    height: 90%;
}

.closedRest:before {
    content: attr(data-alertText);
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    font-size: 1.05rem;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: rgba(0,0,0,0.6);
    color: white;
    z-index: 8;
}

.alert-badge-padding{
    height: 2rem;
    //padding:0.5rem
}

.alert-badge{
    color:black;
    display: flex;
    font-weight: bold;
    font-size: 0.8rem;
    padding-left: 1rem;
    align-items: center;
    background-color: #fdbe00;
    line-height: 1em;
}

#cont circle {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s linear;
    stroke: #666;
    stroke-width: 1em;
  }
#cont #bar {
stroke: #FF9F1E;
}
#cont {
display: block;
height: 200px;
width: 200px;
margin: 2em auto;
box-shadow: 0 0 1em black;
border-radius: 100%;
position: relative;
}

@media (max-width: 768px) {
    .menuCategorySection {
    padding-right: 0 !important;
    }
}
.howToUseCredits{
    p{
        font-size: 16px;
    }
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: red;
    border-color: red;
}
.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before{
    background-color: rgba(255, 0, 0, 0.5);
}